import React from 'react'
import { Link } from 'react-router-dom'

function ListaMembros(props) {

    return (
        <table className="table table-hover table-bordered">
        <thead>
            <tr className="table-secondary">
                <th scope="col">Nome</th>
                <th scope="col">Telefone</th>
                <th scope="col">Lider GV</th>
                <th scope="col" className="col-acao"></th>
            </tr>
        </thead>
        <tbody>
            {
                props.arrayContato.map((membros) => {
                    return (
                        <tr key={membros.id}>
                            <td>{membros.name}</td>
                            <td>{membros.phone}</td>
                            <td>{membros.liderGV ? 'Sim' : 'Não'}</td>
                            <td>
                                <Link to={`/alterarmembros/${membros.id}`}><i className="fas fa-edit icone-acao"></i></Link> 
                                <Link to='/consultamembros' onClick={() => props.clickDelete(membros.id)}><i className="far fa-trash-alt icone-acao red"></i></Link>
                            </td>
                        </tr>
                    )
                })
            }
        </tbody>
    </table>
           )
}

export default ListaMembros