import React, { useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import ListaReunioes from './listareunioes'
import { format } from 'date-fns'
import localePt from 'date-fns/locale/pt'

import Menu from '../../home/components/menu'

import { db } from '../../config/firebase'
import { collection, query, where, getDocs, orderBy, deleteDoc, doc } from 'firebase/firestore'
import SweetAlert from 'react-bootstrap-sweetalert'
import { getNameLider } from '../../common/funcoesuniversais'

function ReuniaoesFeitas() {

    const params = useParams()
    const [reunioesgv, setReunioesGV] = useState([])
    const [busca, setBusca] = useState('')
    const [texto, setTexto] = useState('')  
    const [excluido, setExcluido] = useState('')
    const [confirmacao, setConfirmacao] = useState(false)
    const [confirmacaoId, setConfirmacaoId] = useState('')

    function deleteReuniao(id){
        deleteDoc(doc(db, 'groupmeetings', id))
          setExcluido(id)
          setConfirmacao(false)
    }  

    function confirmDelete(id){
        setConfirmacaoId(id)
        setConfirmacao(true)
    }   

    async function GetDados() {
        const listaReunioesGV = []

        const q = query(collection(db, 'groupmeetings'), where('idgrupo', '==', params.id), orderBy('data'))
        const querySnapshot = await getDocs(q)
        await Promise.all(querySnapshot.docs.map(async (doc) => {
            const idLider = doc.data().idlider
            const nameLider = await getNameLider(idLider)
            if (doc.data().idlider.indexOf(busca) >= 0) {
                const dataFormatada = format(new Date(doc.data().data.seconds * 1000), 'dd-MM-yyyy', { locale: localePt, })
                listaReunioesGV.push({
                    id: doc.id,
                    idgrupo: doc.data().idgrupo,
                    idlider: doc.data().idlider,
                    lider: nameLider,
                    theme: doc.data().theme,
                    data: dataFormatada,
                    present: doc.data().present,
                    adrress: doc.data().adrress,
                    photo: doc.data().photo
                })
            }
        }))  
        listaReunioesGV.sort((a, b) => a.lider.localeCompare(b.lider))
        setReunioesGV(listaReunioesGV)
    } 
  
    useEffect(() => {
        GetDados()
    }, [busca, excluido])   

    return (
        <>
            <Menu/>
            <div className="container-fluid titulo">   
                    <ul className="text-center">
                        <h1>Registar reuniões de grupos de vida</h1>  
                    </ul>  
                    <div className="row">
                        <div className="col-4">
                            <Link to={`/inserirreuniao/${'novo'}/${params.idlider}/${params.adrress}/${params.id}/${undefined}`} className="btn btn-primary" type="button"><i className="fas fa-plus"></i> Inserir</Link>
                        </div>                        

                        <div className="col-8">
                            <div className="input-group mb-3">
                                <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar" aria-describedby="button-addon2" id='inputserach'/>
                                <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                            </div>
                        </div>
                    </div>  
                    <ListaReunioes arrayReunioesGV={reunioesgv} clickDelete={confirmDelete}/>   
                    {
                confirmacao ? <SweetAlert
                                warning
                                showCancel
                                showCloseButton
                                confirmBtnText="Sim"
                                confirmBtnBsStyle="danger"
                                cancelBtnText="Não"
                                cancelBtnBsStyle="light"
                                title="Exclusão"
                                onConfirm={() => deleteReuniao(confirmacaoId)}
                                onCancel={() => setConfirmacao(false)}          
                                reverseButtons={true}
                            >
                                Deseja excluir a reunião selecionada?
                            </SweetAlert> : null 
                }                        
                </div> 
        </>
    )
}

export default ReuniaoesFeitas