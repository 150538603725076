import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import Menu from '../../home/components/menu'

import { auth } from '../../config/firebase'
import { onAuthStateChanged } from 'firebase/auth'

import Formulario from './components/formulario'

function RegistrarMembros() {

    const [userIsLoged, setUserLoged] = useState(true)
    const params = useParams()

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true)
            } else {
                setUserLoged(false)
            }
        }) 
    }, [])

    return (
            <div>
                <Menu/>
                {
                    userIsLoged === false ? <Navigate to="/" replace={true} /> : null
                }            
                <Formulario idmembro={params.id}/>    
           </div>
           )
}

export default RegistrarMembros