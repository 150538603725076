import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import Menu from '../../home/components/menu'

import { auth } from '../../config/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import Formulario from './components/formulario'

function EditarHome() {

    const [userIsLoged, setUserLoged] = useState(true)

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true)
            } else {
                setUserLoged(false)
            }
        }) 
    }, [])

    return (
            <div>
                <Menu/>
                {
                    userIsLoged === false ? <Navigate to="/" replace={true} /> : null
                }
                <Formulario/>           
           </div>
           )
}

export default EditarHome