import React, { useState, useEffect } from 'react'
import { Navigate, Link } from 'react-router-dom'
import './formulario.css'

import { db } from '../../../config/firebase'
import { collection, doc, query, where, getDocs, addDoc, updateDoc, serverTimestamp } from 'firebase/firestore'
import SweetAlert from 'react-bootstrap-sweetalert'

function Formulario() {
    const [iid, setIid] = useState('')
    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [sucesso, setSucesso] = useState('')
    const [confirmacao, setConfirmacao] = useState(false)
    const [mensagem, setMensagem] = useState('')

    async function handlesubmitHome() {
        if (title.length === 0 || text.length === 0){
            setMensagem('* Informe todos os campos obrigatórios');
            return
        }


        try {
            if (iid != '') {
                const docRef = doc(db, 'content', iid)
                await updateDoc(docRef, {
                    name: 'home',
                    title: title,
                    text: text,
                    updated_at: serverTimestamp()
                })
            } else {
                const docRef = await addDoc(collection(db, 'content'),{
                    name: 'home',
                    title: title,
                    text: text,
                    created_at: serverTimestamp(),
                    updated_at: serverTimestamp()
                })
            }
            setSucesso('S')
        } catch(error) {
            setSucesso('N')
            console.error("Error adding document: ", error)
        }
    }

    async function getData() {
        const q = query(collection(db, 'content'), where('name', '==', 'home'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) =>{
            setName(doc.data().name)
            setTitle(doc.data().title)
            setText(doc.data().text)
            setIid(doc.id)
        })
    }

    useEffect(()=> {
        getData()
    }, [])


    return(
        <div className="offset-lg-3 col-lg-6">
        <div>
            <ul className="text-center">
                <h1>Alterar informações da tela de início</h1>
            </ul>
        </div>
        <form>
            <div className="container-fluid align-self-center">
                <div className="form-group mb-3">
                    <label htmlFor="inputtitle" id='lbltitle'><i className="fas fa-heading"></i> Título</label> <label style={{color: 'red'}} >*</label>
                    <input defaultValue={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="Título" id='inputtitle' required/>
                </div>
            </div>
            <div className="container-fluid">
                <div className="form-group mb-3">
                    <label htmlFor="exampleFormControlTextarea1"><i className="fas fa-clipboard"></i> Texto</label> <label style={{color: 'red'}} >*</label>
                    <textarea defaultValue={text} onChange={(e) => setText(e.target.value)} className='form-control' id="exampleFormControlTextarea1" rows="6" required></textarea>
                </div>
            </div>
            <div className="container-fluid">
                {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert"><label>{mensagem}</label></div> : null}
            </div>
            <div className="d-flex justify-content-center">
                <Link to='/adm' className="w-50 btn btn-danger btn-acao" type="button"><i className="fas"></i> Cancelar</Link>
                <button onClick={handlesubmitHome} type="button" className="w-50 btn btn-primary btn-acao">Guardar</button>
                {
                    sucesso === 'S' ? <SweetAlert
                                        success
                                        title="Success!"
                                        onConfirm={() => setConfirmacao(true)}
                                        onCancel={() => setSucesso('N')}
                                        timeout={2000}
                                    >
                                        Registro gravado com sucesso!
                                    </SweetAlert> : null
                }   
                {
                    confirmacao ? <Navigate to={`/adm`} replace={true} /> : null
                }                             
            </div>
        </form>
        </div>
    )
}

export default Formulario