import React, { useState, useEffect } from 'react'
import { Navigate, Link } from 'react-router-dom'

import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import './formulario.css'

import { db } from '../../../config/firebase'
import { collection, doc, getDoc, addDoc, updateDoc, serverTimestamp } from 'firebase/firestore'
import { auth } from '../../../config/firebase'
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import SweetAlert from 'react-bootstrap-sweetalert'

//import { createUser } from '../../../common/funcoesuniversais'

function Formulario(props) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [adrress, setAdrress] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [adm, setAdm] = useState(false)
    const [liderGV, setLiderGV] = useState(false)
    const [worshipMinistry, setWorshipMinistry] = useState(false)
    const [ministeryLider, setMinisteryLider] = useState(false)
    //const [ministerio, setMinisterio] = useState('')
    const [obs, setObs] = useState('')
    const [sucesso, setSucesso] = useState('')
    const [idUserMail,setIdUserMail] = useState('')
    const [confirmacao, setConfirmacao] = useState(false)
    const [mensagem, setMensagem] = useState('')

    async function getDadosMembros(id) {
        const docRef = doc(db, 'users', id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            setName(docSnap.data().name)
            setEmail(docSnap.data().email)
            setPhone(docSnap.data().phone)
            setAdrress(docSnap.data().adrress)
            setZipcode(docSnap.data().zipcode)
            setCity(docSnap.data().city)
            setCountry(docSnap.data().country)
            setAdm(docSnap.data().adm)
            setLiderGV(docSnap.data().liderGV)
            setWorshipMinistry(docSnap.data().worshipMinistry)
            setMinisteryLider(docSnap.data().ministeryLider)
            setObs(docSnap.data().obs)
        }
    }

    useEffect(() => {
        if (props.idmembro != undefined) {
            getDadosMembros(props.idmembro)
        }
    }, [sucesso])

    async function createUser(email) {
        await createUserWithEmailAndPassword(auth, email, 'ibn123456')
        .then((userCredential) => {
            setIdUserMail(userCredential.user?.uid) 
            //sendEmailVerification(userCredential.user)
        })
        .catch((error) => {
            const errorCode = error.code
            const errorMessage = error.message
            console.log(errorCode + ' ' + errorMessage)
          })
    }

    async function handlesubmitMembro() {
        if (!name){
            setMensagem('* Informe todos os campos obrigatórios')
            return;
        }
        if (!email && adm || !email && liderGV) {
            setMensagem('* Em caso de administrador ou lider de grupo, é obrigatório preencher o e-mail')
            return;            
        }
        if (!email == '' && liderGV && props.idmembro === undefined) {
            await createUser(email)
        }
        try {
            if (props.idmembro === undefined) {
                const docRef = await addDoc(collection(db, 'users'), {
                    name: name,
                    email: email,
                    phone: phone,
                    adrress: adrress,
                    zipcode: zipcode,
                    city: city,
                    country: country,
                    adm: adm,
                    liderGV: liderGV,
                    worshipMinistry: worshipMinistry,
                    ministeryLider: ministeryLider,
                    obs: obs,
                    idUserMail: idUserMail,
                    created_at: serverTimestamp(),
                    updated_at: serverTimestamp()
                })
            } else {
                const docRef = doc(db, 'users', props.idmembro)
                await updateDoc(docRef, {
                    name: name,
                    email: email,
                    phone: phone,
                    adrress: adrress,
                    zipcode: zipcode,
                    city: city,
                    country: country,
                    adm: adm,
                    liderGV: liderGV,
                    worshipMinistry: worshipMinistry,
                    ministeryLider: ministeryLider,
                    obs: obs,
                    updated_at: serverTimestamp()
                })
            }       
            setSucesso('S') 
        } catch(e) {
            setSucesso('N')
            console.error("Error adding document: ", e)
        }
    }

    return(
        <div className="offset-lg-3 col-lg-6">
        <div>
            <ul className="text-center">
                <h1>Registro de membro</h1>
            </ul>
        </div>
        <form id="myform">
            <div className="container-fluid align-self-center">
                <div className="form-group mb-3">
                    <label htmlFor="inputname"><i className="fas fa-user"></i> Nome</label><label style={{color: 'red'}} >*</label>
                    <input defaultValue={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" placeholder="Nome" id="inputname"/>
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="inputemail"><i className="fas fa-envelope"></i> E-mail</label>
                    <input defaultValue={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="inputemail" placeholder="name@example.com" />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="inputphone"><i className="fas fa-phone"></i> Telefóne</label>
                    <PhoneInput 
                        id="inputphone"
                        country={'pt'} 
                        enableSearch 
                        inputStyle={{ width: '100%' }} 
                        buttonStyle={{ width: '50px', height: '35px', marginTop: '5px' }}
                        value={phone}
                        onChange={(e) => setPhone(e)} 
                        className="form-control" />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="inputadress"><i className="fas fa-map"></i> Morada</label>
                    <input defaultValue={adrress} onChange={(e) => setAdrress(e.target.value)} type="text" className="form-control mb-3" id="inputadress" placeholder="Morada" />
                    <input 
                        defaultValue={zipcode}
                        onChange={(e) => setZipcode(e.target.value)} 
                        className="form-control mb-3" 
                        id="inputzipcode"
                        placeholder="Código postal" />
                    <input defaultValue={city} onChange={(e) => setCity(e.target.value)} type="text" className="form-control mb-3" id="inputcity" placeholder="Cidade" />  
                    <input defaultValue={country} onChange={(e) => setCountry(e.target.value)} type="text" className="form-control mb-3" id="inputcountry" placeholder="Pais" />               
                </div>  
                <div className="form-check mb-3">
                    <input checked={adm} onChange={(e) => setAdm(e.target.checked)} type="checkbox" className="form-check-input" id="flexCheckChecked" />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Admimistrador
                    </label>
                </div>
                <div className="form-check mb-3">
                    <input defaultValue={liderGV} checked={liderGV} onChange={(e) => setLiderGV(e.target.checked)}  type="checkbox" className="form-check-input" id="flexCheckChecked1" />
                    <label className="form-check-label" htmlFor="flexCheckChecked1">
                        Lider de grupo de vida
                    </label>
                </div>
                <div className="form-check mb-3">
                    <input checked={worshipMinistry} onChange={(e) => setWorshipMinistry(e.target.checked)} type="checkbox" className="form-check-input" id="flexCheckChecked2" />
                    <label className="form-check-label" htmlFor="flexCheckChecked2">
                        Ministério de louvor
                    </label>
                </div>
                <div className="form-check mb-3">
                    <input checked={ministeryLider} onChange={(e) => setMinisteryLider(e.target.checked)} type="checkbox" className="form-check-input" id="flexCheckChecked3" />
                    <label className="form-check-label" htmlFor="flexCheckChecked3">
                        Líder de ministério
                    </label>
                </div>                
            </div>         
            <div className="container-fluid">
                <div className="form-group">
                    <div className="form-group mb-3">
                        <label htmlFor="exampleFormControlTextarea1"><i className="fas fa-asterisk"></i> Observações</label>
                        <textarea defaultValue={obs} onChange={(e) => setObs(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert"><label>{mensagem}</label></div> : null}
            </div> 
            <div className="d-flex justify-content-center">
                <Link to='/consultamembros' className="w-50 btn btn-danger btn-acao" type="button"><i className="fas"></i> Cancelar</Link>
                <button onClick={handlesubmitMembro} type="button" className="w-50 btn btn-primary btn-acao">Guardar</button>
                {
                    sucesso === 'S' ? <SweetAlert
                                        success
                                        title="Success!"
                                        onConfirm={() => setConfirmacao(true)}
                                        onCancel={() => setSucesso('N')}
                                        timeout={2000}
                                    >
                                        Registro gravado com sucesso!
                                    </SweetAlert> : null
                }
                {
                    confirmacao ? <Navigate to={`/consultamembros`} replace={true} /> : null
                }                
            </div>
        </form>
    </div>
    );
}

export default Formulario