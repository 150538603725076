import React, { useEffect, useState } from 'react'
import { Navigate, Link } from 'react-router-dom'
import Menu from '../../home/components/menu'

import { db, auth } from '../../config/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { collection, getDocs, orderBy, doc, deleteDoc, query } from 'firebase/firestore'

import { format } from 'date-fns'
import localePt from 'date-fns/locale/pt'

import ListaMensagensEdit from './components/listamensagemedit'
import SweetAlert from 'react-bootstrap-sweetalert'

function MensagemGV() {

    const [userIsLoged, setUserLoged] = useState(true)
    const [mensagens, setMensagens] = useState([])
    const [busca, setBusca] = useState('')
    const [texto, setTexto] = useState('')
    const [excluido, setExcluido] = useState('')
    const [confirmacao, setConfirmacao] = useState(false)
    const [confirmacaoId, setConfirmacaoId] = useState('')

    function deleteUser(id){
        deleteDoc(doc(db, 'message', id))
          setExcluido(id)
          setConfirmacao(false)
    }    

    function confirmDeleteUser(id){
        setConfirmacaoId(id)
        setConfirmacao(true)
    }

    async function GetMessage() {
        const listaMessagens = []

        const q = query(collection(db, 'message'), orderBy('iniWeek'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
            if (doc.data().title.indexOf(busca) >= 0) {
                const dataFormatadaIni = format(new Date(doc.data().iniWeek.seconds * 1000), "dd-MM-yyyy", { locale: localePt, })
                const dataFormatadaFim = format(new Date(doc.data().fimWeek.seconds * 1000), "dd-MM-yyyy", { locale: localePt, })
                listaMessagens.push({
                    id: doc.id,
                    title: doc.data().title,
                    iniWeek: dataFormatadaIni,
                    fimWeek: dataFormatadaFim,
                    readonly: false
                })
            }
            setMensagens(listaMessagens)
        })
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true)
                GetMessage()
            } else {
                setUserLoged(false)
            }
        }) 
    }, [busca, excluido])

    return (
        <>
            <Menu/>
            <div className="container-fluid titulo">   
                <ul className="text-center">
                    <h1>Lista de mensagens para grupo de vida</h1>  
                </ul>  
                <div className="row">
                    <div className="col-4">
                        <Link to='/registromensagens' className="btn btn-primary" type="button"><i className="fas fa-plus"></i> Inserir</Link>
                    </div>

                    <div className="col-8">
                        <div className="input-group mb-3">
                            <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por título" aria-describedby="button-addon2" id='inputserach'/>
                            <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                        </div>
                    </div>
                </div>
                <ListaMensagensEdit arrayMessagens={mensagens} clickDelete={confirmDeleteUser} />
                {
                confirmacao ? <SweetAlert
                                warning
                                showCancel
                                showCloseButton
                                confirmBtnText="Sim"
                                confirmBtnBsStyle="danger"
                                cancelBtnText="Não"
                                cancelBtnBsStyle="light"
                                title="Exclusão"
                                onConfirm={() => deleteUser(confirmacaoId)}
                                onCancel={() => setConfirmacao(false)}          
                                reverseButtons={true}
                            >
                                Deseja excluir a mensagem selecionada?
                            </SweetAlert> : null 
                }                
                {
                    userIsLoged === false ? <Navigate to="/" replace={true} /> : null
                }                
           </div>
        </>
           )
}

export default MensagemGV