import React, { useEffect, useState }  from 'react'
import { Link, Navigate } from 'react-router-dom'
import Menu from '../../home/components/menu'
import ListarGV from './components/listagruposvida'

import { db, auth } from '../../config/firebase'
import { collection, getDocs, orderBy, deleteDoc, doc, query } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import SweetAlert from 'react-bootstrap-sweetalert'
import { getNameLider } from '../../common/funcoesuniversais'

function ConsultaGV() {

    const [userIsLoged, setUserLoged] = useState(true)
    const [gruposvida, setGruposVida] = useState([])
    const [busca, setBusca] = useState('')
    const [texto, setTexto] = useState('')  
    const [excluido, setExcluido] = useState('')
    const [confirmacao, setConfirmacao] = useState(false)
    const [confirmacaoId, setConfirmacaoId] = useState('')    
    
    function deleteGrupoVida(id){
        deleteDoc(doc(db, 'gruposdevida', id))
          setExcluido(id)
          setConfirmacao(false)
    }    

    function confirmDelete(id){
        setConfirmacaoId(id)
        setConfirmacao(true)
    }

    async function GetGrupoVida() {
        const listaGrupoVida = []

        const q = query(collection(db, 'gruposdevida'), orderBy('idlider'))
        const querySnapshot = await getDocs(q)
        await Promise.all(querySnapshot.docs.map(async (doc) => {
            const idLider = doc.data().idlider
            const nameLider = await getNameLider(idLider)
            if (doc.data().idlider.indexOf(busca) >= 0) {
                listaGrupoVida.push({
                    id: doc.id,
                    idlider: doc.data().idlider,
                    lider: nameLider,
                    adrress: doc.data().adrress,
                    zipcode: doc.data().zipcode,
                    city: doc.data().city,
                    day: doc.data().day,
                    meeting: false
                })                
            }
            
        }))
        // Ordenar os dados pelo campo "lider"
        listaGrupoVida.sort((a, b) => a.lider.localeCompare(b.lider))
        setGruposVida(listaGrupoVida)
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true)
            } else {
                setUserLoged(false)
            }
        }) 
    }, [])

    useEffect(() => {
        GetGrupoVida()
    }, [busca,excluido]); 

    return (
        <>
            <Menu/>
            <div className="container-fluid titulo">   
                <ul className="text-center">
                    <h1>Lista de grupos de vida</h1>    
                </ul>
                <div className="row">
                    <div className="col-4">
                        <Link to='/inserirgv' className="btn btn-primary" type="button"><i className="fas fa-plus"></i> Inserir</Link>
                    </div>

                    <div className="col-8">
                        <div className="input-group mb-3">
                            <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar" aria-describedby="button-addon2" id='inputserach'/>
                            <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                        </div>
                    </div>
                </div>
                <ListarGV arrayGruposVida={gruposvida} clickDelete={confirmDelete} />
                {
                confirmacao ? <SweetAlert
                                warning
                                showCancel
                                showCloseButton
                                confirmBtnText="Sim"
                                confirmBtnBsStyle="danger"
                                cancelBtnText="Não"
                                cancelBtnBsStyle="light"
                                title="Exclusão"
                                onConfirm={() => deleteGrupoVida(confirmacaoId)}
                                onCancel={() => setConfirmacao(false)}          
                                reverseButtons={true}
                            >
                                Deseja excluir o registro selecionada?
                            </SweetAlert> : null 
                } 
            </div>
            {
                userIsLoged === false ? <Navigate to="/" replace={true} /> : null
            }                
        </>
    )
}

export default ConsultaGV