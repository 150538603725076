import React from 'react'
import { Link } from 'react-router-dom'

function ListaColheita(props) {
  return ( 
    <table className="table table-hover table-bordered">
    <thead>
    <tr className="table-secondary">
        <th scope="col">Tema</th>
        <th scope="col">Dia</th>
        <th scope="col">Presentes</th>
        <th scope="col" className="col-acao2"></th>
    </tr>
</thead>
<tbody>
    {
        props.arrayColheita.map((colheita) => {
            return (
                <tr key={colheita.id}>
                    <td>{colheita.theme}</td>
                    <td>{colheita.day}</td>
                    <td>{colheita.presents}</td>
                    <td>
                       <Link to={`/inserircolheita/${colheita.id}`}><i className="fas fa-edit icone-acao2"></i></Link>
                       <Link to='/colheita' onClick={() => props.clickDelete(colheita.id)}><i className="far fa-trash-alt icone-acao2 red"></i></Link>
                    </td>
                </tr>
            )
        })
    }
</tbody>        
</table>
  )
}
export default ListaColheita