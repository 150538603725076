import React, { useState, useEffect } from 'react'
import logoMavBar from '../../assets/img/menu.jpg'

import { auth } from '../../config/firebase'
import { signOut } from 'firebase/auth'

function Menu () {
    const [userIsAdm, setUserAdm] = useState('')
    const [userIsLiderGV, setUserIsLiderGV] = useState('')

    function isAdm() {
      return localStorage.getItem('userAdm')
    }

    function isLiderGV() {
      return localStorage.getItem('userLiderGV')
    }

    useEffect( () => {
      setUserAdm( isAdm() ) 
      setUserIsLiderGV( isLiderGV())
    }, [])

    function LogOffUser() {
      signOut(auth)
      .then(()=> {
        localStorage.clear()
      })
      .catch((error)=> {
        console.log(error)
      } )
    }
    return (  
    <>
        <nav className="navbar navbar-expand-md navbar-dark">
          <div className='container'> 
            <a className="navbar-brand" href="/home">
              <img src={logoMavBar} alt="" width="56" height="38"/>
            </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/home">Início</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/mensagensgv">Mensagens Grupo de Vida</a>
              </li>
              { userIsLiderGV == 'true' ? 
                <>
                  <li className="nav-item"><a className="nav-link" aria-current="page" href="/inserirreuniaosgv">Registar reunião</a></li>
                </> : null
              }
              { userIsAdm === 'true' ? 
                <>
                  <li className="nav-item"><a className="nav-link" aria-current="page" href="/adm">Administração</a></li>
                </> : null
              }      
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/" onClick={LogOffUser}>Terminar</a>
              </li>        
            </ul>
          </div>    
        </div>   
      </nav>
  </>
    )
}

export default Menu