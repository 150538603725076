import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Menu from './components/menu'

import { auth, db } from '../config/firebase'
import { collection, query, where, getDocs, Timestamp, limit } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'

import { startOfWeek, endOfWeek } from 'date-fns'

function Home () {

    const isMobile = useMediaQuery({ maxWidth: 767 })
    const [userIsLoged, setUserLoged] = useState()
    const [titleGV, setTitleGV] = useState()
    const [textGV, setTextGV] = useState()
    const [titleMsg, setTitleMsg] = useState()
    const [idMsg, setIdMsg] = useState('')
    const [userIsLiderGV, setUserIsLiderGV] = useState('')

    const obterPrimeiroUltimoDiaSemana = () => {
        const hoje = new Date()
        const primeiroDiaSemana = startOfWeek(hoje, { weekStartsOn: 0 }) // Onde 1 é segunda-feira
        const ultimoDiaSemana = endOfWeek(hoje, { weekStartsOn: 0 })
      
        return {
          primeiroDia: primeiroDiaSemana,
          ultimoDia: ultimoDiaSemana,
        }
    }

    async function getInfoHome() {
        const q = query(collection(db, "content"), where("name", "==", "home"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setTitleGV(doc.data().title)
            setTextGV(doc.data().text)
        })
    }

    async function getMensagemSemana() {
        const { primeiroDia, ultimoDia } = obterPrimeiroUltimoDiaSemana()
        const dateIni = Timestamp.fromDate(primeiroDia);
        //const dateFim = Timestamp.fromDate(ultimoDia);
        const q = query(collection(db, 'message'), 
                        where('iniWeek', '>=', dateIni), 
                        limit(1))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
            setIdMsg(doc.id)
            setTitleMsg(doc.data().title)
        })
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true)
                //setUserAdm(userIsAdministrator())
                getInfoHome()
                setUserIsLiderGV(localStorage.getItem('userLiderGV'))
                getMensagemSemana()
            } else {
                setUserLoged(false)
            }
        }) 
    }, [])

    return (
            <>
                <Menu/>
                {
                    userIsLoged === false ? <Navigate to="/" replace={true} /> : null
                }
                {
                !isMobile ?
                <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="10000">
                        <div className="page-header-ui-content pt-5">
                                <div className="container px-5">
                                    <div className="row gx-5 align-items-center">
                                    { titleMsg != undefined ? <><div className="col-lg-6 aos-init aos-animate" data-aos="fade-up">
                                            <h1 className="page-header-ui-title">Mensagem da semana</h1>
                                            <p className="page-header-ui-text mb-5">{ titleMsg }</p>
                                            <div className="d-flex flex-column flex-sm-row">
                                                 <a className="btn btn-lg btn-primary fw-500 me-sm-3 mb-3 mb-sm-0" href={`/viewmensagem/${idMsg}`}>
                                                    Visualizar mensagem
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right ms-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                                </a> 
                                                { userIsLiderGV == 'true' ? <a className="btn btn-lg btn-primary-soft text-primary fw-500" href="/inserirreuniaosgv">Registar reunião</a> : null}
                                            </div>
                                        </div></> : null}
                                        <div className="col-lg-6 d-none d-lg-block aos-init aos-animate" data-aos="fade-up" data-aos-delay="100"><img className="img-fluid" src="./gv/logogv_preto.png" alt="..."/></div>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div className="carousel-item" data-bs-interval="2000">
                        <div className="page-header-ui-content pt-5">
                            <div className="container px-5">
                                <div className="row gx-5 align-items-center">
                                    <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up">
                                        <h1 className="page-header-ui-title">{ titleGV }</h1>
                                        <p className="page-header-ui-text mb-5">{ textGV }</p>
                                    </div>
                                    <div className="col-lg-6 d-none d-lg-block aos-init aos-animate" data-aos="fade-up" data-aos-delay="100"><img className="img-fluid" src="./gv/gv_preto.png" alt="..."/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
                </div>
                : 
                <>
                    <div id="container" className="container">
                        <img src="./gv/logogv_preto.png" className="d-block w-100" alt="Grupo de vida"/>
                    </div>
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-body">
                                    <h1 className="card-title">{ titleGV }</h1>
                                    {
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: textGV }} ></p>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { titleMsg != undefined ? 
                    <><div className="page-header-ui-content pt-5">
                        <div className="container px-5">
                            <div className="row gx-5 align-items-center">
                                <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up">
                                    <h1 className="page-header-ui-title">Mensagem da semana</h1>
                                    <p className="page-header-ui-text mb-5">{ titleMsg }</p>
                                    <div className="d-flex flex-column flex-sm-row">
                                        <a className="btn btn-lg btn-primary fw-500 me-sm-3 mb-3 mb-sm-0" href={`/viewmensagem/${idMsg}`}>
                                            Visualizar mensagem
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right ms-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        </a>
                                        <a className="btn btn-lg btn-primary-soft text-primary fw-500" href="/inserirreuniaosgv">Registar reunião</a>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-none d-lg-block aos-init aos-animate" data-aos="fade-up" data-aos-delay="100"><img className="img-fluid" src="./gv/logogv_preto.png" alt="..."/></div>
                            </div>
                        </div>
                    </div></> : null}
                </>
                }
           </>
           )
}

export default Home