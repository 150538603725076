import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Menu from '../../../home/components/menu'

import { db } from '../../../config/firebase'
import { doc, getDoc } from 'firebase/firestore'

function VisualizaMensagem() {

    const params = useParams();
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')

    async function GetDadosMessage(id) {
        const docRef = doc(db, 'message', id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            setTitle(docSnap.data().title)
            setMessage(docSnap.data().message)
        }
    }

    useEffect(() => {
        if (params.id != undefined) {
            GetDadosMessage(params.id)
        }
    }, [])   

    return (
        <>
            <Menu/>
            <div className="container mt-5">
            <div className="row">
                <div className="col">
                <div className="card">
                    <div className="card-body">
                    <div className='d-flex justify-content-center'>    
                        <h1 className="card-title">{title}</h1>
                    </div>
                    {
                        <div>
                            <p className="card-text" dangerouslySetInnerHTML={{ __html: message }} ></p>
                        </div>
                    }
                    </div>
                </div>
                </div>
            </div>
            </div>
        </>   
    )
}

export default VisualizaMensagem