import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { auth } from '../../config/firebase' 
import { onAuthStateChanged } from 'firebase/auth'
import Menu from '../../home/components/menu'

import FormularioColheita from './components/formulario'

function CadastroColheita() {
  
  const [userIsLoged, setUserLoged] = useState(true)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            setUserLoged(true)
        } else {
            setUserLoged(false)
        }
    }) 
}, [])

  return (
    <div>
      <Menu/>
      <FormularioColheita/>
      {
          userIsLoged === false ? <Navigate to="/" replace={true} /> : null
      }   
    </div>
  )
}

export default CadastroColheita