import React, { useState, useEffect } from 'react'
import ReactApexCharts from 'react-apexcharts'

function GraficoLinhas(props) {
    const state = {
          
        series: props.arrayRel,
        options: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: 'Gráfico presenças por semana',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: props.arrayRelWeek,
          }
        },  
    }
    return (
        <>
            <ReactApexCharts options={state.options} series={state.series} type="line" height={350} />     
       </>
    )
}

export default GraficoLinhas