import React, { useState, useEffect, useRef } from 'react'
import { Navigate, useParams, Link } from 'react-router-dom'
import Menu from '../../../home/components/menu'
import 'jodit'
import 'jodit/build/jodit.min.css'
import JoditEditor from 'jodit-react'
import './formulario.css'
import { parse, format } from 'date-fns'

import { db } from '../../../config/firebase'
import { collection, doc, getDoc, addDoc, updateDoc, serverTimestamp } from 'firebase/firestore'
import SweetAlert from 'react-bootstrap-sweetalert'

function FormularioMsgGv() {
    const params = useParams()
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [iniWeek, setIniWeek] = useState('')
    const [fimWeek, setFimWeek] = useState('')
    const [sucesso, setSucesso] = useState('')
    const [confirmacao, setConfirmacao] = useState(false)
    const [mensagem, setMensagem] = useState('')

    const editor = useRef(null)

    async function GetDadosMessage(id) {
        const docRef = doc(db, 'message', id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {

            const dataFormatadaIni = format(docSnap.data().iniWeek.seconds* 1000, 'yyy-MM-dd')
            const dataFormatadaFim = format(docSnap.data().fimWeek.seconds* 1000, 'yyy-MM-dd')

            setTitle(docSnap.data().title)
            setMessage(docSnap.data().message)
            setIniWeek(dataFormatadaIni)
            setFimWeek(dataFormatadaFim)
        }
    }

    useEffect(() => {
        if (params.id != undefined) {
            GetDadosMessage(params.id)
        }
    }, [sucesso])

    async function handlesubmitMsgGv() {
        if (!title || !message || !iniWeek || !fimWeek){
            setMensagem('* Informe todos os campos obrigatórios')
            return
        }
        if (fimWeek < iniWeek) {
            setMensagem('Data final deve ser maior de que data final')
            return
        }
        try {
            if (params.id == undefined) {
                const dataInicio = parse(iniWeek, 'yyyy-MM-dd', new Date())
                const dataFim = parse(fimWeek, 'yyyy-MM-dd', new Date())
                const docRef = await addDoc(collection(db,'message'), {
                    title: title,
                    message: message,
                    iniWeek: dataInicio,
                    fimWeek: dataFim,
                    created_at: serverTimestamp(),
                    updated_at: serverTimestamp()
                })
            } else {
                const docRef = doc(db, 'message', params.id)
                const dataInicio = parse(iniWeek, 'yyyy-MM-dd', new Date())
                const dataFim = parse(fimWeek, 'yyyy-MM-dd', new Date())
                await updateDoc(docRef, {
                    title: title,
                    message: message,
                    iniWeek: dataInicio,
                    fimWeek: dataFim,
                    updated_at: serverTimestamp()                  
                })
            }
            setSucesso('S')
        } catch(erro) {
            setSucesso('N')
            console.error("Error adding document: ", e)
        }
    }
    return (
        <>
        <Menu/>
        <div className="offset-lg-3 col-lg-6">
            <div>
                <ul className="text-center">
                    <h1>Registo de mensagens</h1>
                </ul>
            </div>
            <form id="myform">
                <div className="container-fluid align-self-center">
                    <div className="form-group mb-3">
                        <label htmlFor="inputtitle"><i className="fas fa-file"></i> Título mensagem</label><label style={{color: 'red'}} >*</label>
                        <input defaultValue={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="Título" id='inputtitle'/>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor='inpuini'><i className="fas fa-calendar-week"></i> Semana</label>
                        <div className="row justify-content-around">
                            <div className="col-6">
                                <label htmlFor='inpuini'>Inicio</label><label style={{color: 'red'}} >*</label>
                                <input defaultValue={iniWeek} onChange={(e) => setIniWeek(e.target.value)} type="date" className="form-control" placeholder="Semana" id='inpuini' />
                            </div>
                            <div className="col-6">
                                <label htmlFor='inputfim' >Fim</label><label style={{color: 'red'}} >*</label>
                                <input defaultValue={fimWeek} onChange={(e) => setFimWeek(e.target.value)} type="date" className="form-control" placeholder="Semana" id='inputfim' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="form-group mb-3">
                        <label htmlFor="inputtext"><i className="fas fa-envelope"></i> Mensagem</label><label style={{color: 'red'}} >*</label>
                        <JoditEditor
                            ref={editor}
                            value={message}
                            tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => setMessage(newContent)}
                            id="inputtext"
                        />
                    </div>                        
                </div> 
                <div className="container-fluid">
                    {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert"><label>{mensagem}</label></div> : null}
                </div> 
                <div className="d-flex justify-content-center">
                    <Link to='/alterarmsggv' className="w-50 btn btn-danger mb-2 btn-acao" type="button"><i className="fas"></i> Cancelar</Link>
                    <button onClick={handlesubmitMsgGv} type="button" className="w-50 btn btn-primary mb-2 btn-acao">Guardar</button>
                    {
                        sucesso === 'S' ? <SweetAlert
                                            success
                                            title="Success!"
                                            onConfirm={() => setConfirmacao(true)}
                                            onCancel={() => setSucesso('N')}
                                            timeout={2000}
                                        >
                                            Registro gravado com sucesso!
                                        </SweetAlert> : null
                    }
                    {
                        confirmacao ? <Navigate to={`/alterarmsggv`} replace={true} /> : null
                    }
                </div>
            </form>    
        </div> 
        </>
    )
}

export default FormularioMsgGv