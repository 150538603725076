import React, { useState, useEffect }  from 'react'
import { Navigate, Link } from 'react-router-dom'
import './formulario.css'

import { db } from '../../../config/firebase'
import { collection, doc, query, where, getDocs, getDoc, addDoc, updateDoc, serverTimestamp, orderBy } from 'firebase/firestore'
import SweetAlert from 'react-bootstrap-sweetalert'

function Formulario(props) {

    const [idLider, setIdLider] = useState('')
    const [adrress, setAdrress] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [city, setCity] = useState('')
    const [day, setDay] = useState('')
    const [sucesso, setSucesso] = useState('')
    const [confirmacao, setConfirmacao] = useState(false)
    const [mensagem, setMensagem] = useState('')
    const [options, setOptions] = useState([])

    async function getDados(id) {
        const docRef = doc(db, 'gruposdevida', id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            setIdLider(docSnap.data().idlider)
            setAdrress(docSnap.data().adrress)
            setZipcode(docSnap.data().zipcode)
            setCity(docSnap.data().city)
            setDay(docSnap.data().day)
        }
    }

    async function getListaLider() {
        const optionsData = []
        const q = query(collection(db, 'users'), where('liderGV', '==', true), orderBy('name'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) =>{
            optionsData.push({ id: doc.id, ...doc.data() })
        })
        setOptions(optionsData)
    }

    useEffect(() => {
        if (props.id != undefined) {
            getDados(props.id)
        }
        getListaLider()
    }, []);   
    
    async function handlesubmit() {
        if (idLider.length === 0 || adrress.length === 0 || day.length === 0){
            setMensagem('* Informe todos os campos obrigatórios')
            return
        }
        try {
            if (props.id === undefined) {
                const docRef = await addDoc(collection(db, 'gruposdevida'), {
                    idlider: idLider,
                    adrress: adrress,
                    zipcode: zipcode,
                    city: city,
                    day: day,
                    created_at: serverTimestamp(),
                    updated_at: serverTimestamp()
                })
            } else {
                const docRef = doc(db, 'gruposdevida', props.id)
                await updateDoc(docRef, {
                    idlider: idLider,
                    adrress: adrress,
                    zipcode: zipcode,
                    city: city,
                    day: day,
                    updated_at: serverTimestamp()         
                })
            }
            setSucesso('S') 
        } catch(erro) {
            setSucesso('N')
            console.error("Error adding document: ", erro)
        }
    }

    return (
        <div className="offset-lg-3 col-lg-6">
            <div>
                <ul className="text-center">
                    <h1>Registro de grupos de vida</h1>
                </ul>
            </div>
            <form id="myform">
                <div className="container-fluid align-self-center">
                    <div className="mb-3">
                        <label htmlFor="selectlider"><i className="fas fa-user-tie"></i> Líder do grupo</label><label style={{color: 'red'}} >*</label>
                        <select className="form-control" id="selectlider" value={idLider} onChange={(e) => setIdLider(e.target.value)}>
                            <option value="">Selecionar líder...</option>
                            {options.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name} {/* Substitua 'nome' pelo campo desejado no documento */}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputadress"><i className="fas fa-map"></i> Morada</label><label style={{color: 'red'}} >*</label>
                        <input defaultValue={adrress} onChange={(e) => setAdrress(e.target.value)} type="text" className="form-control mb-3" id="inputadress" placeholder="Morada" />
                        <input 
                            defaultValue={zipcode}
                            onChange={(e) => setZipcode(e.target.value)} 
                            className="form-control mb-3" 
                            id="inputzipcode"
                            placeholder="Código postal" />
                        <input defaultValue={city} onChange={(e) => setCity(e.target.value)} type="text" className="form-control mb-3" id="inputcity" placeholder="Cidade" />     
                    </div>  
                    <div className="mb-3">
                        <label htmlFor="selectday"><i className="fas fa-calendar-week"></i> Dia da semana do grupo de vida</label><label style={{color: 'red'}} >*</label>
                        <select className="form-control" id="selectday" value={day} onChange={(e) => setDay(e.target.value)}>
                            <option value="">Selecionar dia...</option>
                            <option key={0} value={'Donmingo'} >Domingo</option>
                            <option key={1} value={'Segunda-feira'}>Segunda-feira</option>
                            <option key={2} value={'Terça-feira'}>Terça-feira</option>
                            <option key={3} value={'Quarta-feira'}>Quarta-feira</option>
                            <option key={4} value={'Quinta-feira'}>Quinta-feira</option>
                            <option key={5} value={'Sexta-feira'}>Sexta-feira</option>
                            <option key={6} value={'Sábado'}>Sábado</option>
                        </select>
                    </div>
                    <div className="container-fluid">
                        {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert"><label>{mensagem}</label></div> : null}
                    </div> 
                    <div className="d-flex justify-content-center">
                        <Link to='/consultagv' className="w-50 btn btn-danger btn-acao" type="button"><i className="fas"></i> Cancelar</Link>
                        <button onClick={handlesubmit} type="button" className="w-50 btn btn-primary btn-acao">Guardar</button>
                        {
                            sucesso === 'S' ? <SweetAlert
                                                success
                                                title="Success!"
                                                onConfirm={() => setConfirmacao(true)}
                                                onCancel={() => setSucesso('N')}
                                                timeout={2000}
                                            >
                                                Registro gravado com sucesso!
                                            </SweetAlert> : null
                        }
                        {
                            confirmacao ? <Navigate to='/consultagv' replace={true} /> : null
                        }
                    </div>                    
                </div>
            </form>
        </div>
    )
}

export default Formulario