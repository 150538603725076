import React, { useEffect, useState }  from 'react'
import { Navigate, Link } from 'react-router-dom'
import { format } from 'date-fns'
import localePt from 'date-fns/locale/pt'
import Menu from '../../home/components/menu'
import { db, auth } from '../../config/firebase'
import { collection, getDocs, orderBy, deleteDoc, doc, query } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import SweetAlert from 'react-bootstrap-sweetalert'

import ListaColheita from './components/listacolheita'

function ConsultaColheita() {

  const [userIsLoged, setUserLoged] = useState(true)
  const [colheita, setColheita] = useState([])
  const [busca, setBusca] = useState('')
  const [texto, setTexto] = useState('')  
  const [excluido, setExcluido] = useState('')
  const [confirmacao, setConfirmacao] = useState(false)
  const [confirmacaoId, setConfirmacaoId] = useState('') 

  function deleteColheita(id) {
    deleteDoc(doc(db, 'colheita', id))
    setExcluido(id)
    setConfirmacao(false)
  }

  function confirmDelete(id){
    setConfirmacaoId(id)
    setConfirmacao(true)
  }  
  async function getColheita() {
    const lista = []

    const q = query(collection(db, 'colheita'), orderBy('day'))
    const querySnapshot = await getDocs(q)
    await Promise.all(querySnapshot.docs.map(async (doc) => {
      if (doc.data().theme.indexOf(busca) >= 0) {
          const dataFormatada = format(new Date(doc.data().day.seconds * 1000), 'dd-MM-yyyy', { locale: localePt, })
          lista.push({
              id: doc.id,
              theme: doc.data().theme,
              presents: doc.data().presents,
              day: dataFormatada,
           })                
        }    
    }))  
    setColheita(lista)
  }

  useEffect(() => {
      onAuthStateChanged(auth, (user) => {
          if (user) {
              setUserLoged(true)
          } else {
              setUserLoged(false)
          }
      }) 
  }, [])

  useEffect(() => {
    getColheita()
  }, [busca,excluido]); 

  return (
    <>
      <Menu/>
        <div className="container-fluid titulo">   
        <ul className="text-center">
            <h1>Lista encontro do dia do Encontro</h1>    
        </ul>
        <div className="row">
            <div className="col-4">
                <Link to='/inserircolheita' className="btn btn-primary" type="button"><i className="fas fa-plus"></i> Inserir</Link>
            </div>

            <div className="col-8">
                <div className="input-group mb-3">
                    <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar" aria-describedby="button-addon2" id='inputserach'/>
                    <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                </div>
            </div>
        </div>           
      <ListaColheita arrayColheita={colheita} clickDelete={confirmDelete} />
      {
      confirmacao ? <SweetAlert
                      warning
                      showCancel
                      showCloseButton
                      confirmBtnText="Sim"
                      confirmBtnBsStyle="danger"
                      cancelBtnText="Não"
                      cancelBtnBsStyle="light"
                      title="Exclusão"
                      onConfirm={() => deleteColheita(confirmacaoId)}
                      onCancel={() => setConfirmacao(false)}          
                      reverseButtons={true}
                  >
                      Deseja excluir o registro selecionada?
                  </SweetAlert> : null 
      }       
      </div>
      {
          userIsLoged === false ? <Navigate to="/" replace={true} /> : null
      }  
    </>
  )
}

export default ConsultaColheita