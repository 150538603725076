import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import Menu from '../../home/components/menu'

import ListarGV from '../../consultas/grupovida/components/listagruposvida'

import { auth, db } from '../../config/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore'
import { getNameLider } from '../../common/funcoesuniversais'

function ReuniaoGrupoVida() {
   
    const [gruposvida, setGruposVida] = useState([])
    const [userIsLoged, setUserLoged] = useState(true)
    const [busca, setBusca] = useState('')
    const [texto, setTexto] = useState('')     

    async function GetDados() {
        const listaGrupoVida = []
        let q
        if (localStorage.getItem('userAdm') === 'true') {
            q = query(collection(db, 'gruposdevida'), orderBy('idlider'))
        } else {
            q = query(collection(db, 'gruposdevida'), where('idlider', '==', localStorage.getItem('userId')), orderBy('idlider'))
        }
        const querySnapshot = await getDocs(q)
        await Promise.all(querySnapshot.docs.map(async (doc) => {
            const idLider = doc.data().idlider
            const nameLider = await getNameLider(idLider)
            if (doc.data().idlider.indexOf(busca) >= 0) {
                listaGrupoVida.push({
                    id: doc.id,
                    idlider: doc.data().idlider,
                    lider: nameLider,
                    adrress: doc.data().adrress,
                    zipcode: doc.data().zipcode,
                    city: doc.data().city,
                    day: doc.data().day,
                    meeting: true
                })
            }
            
        }))
        listaGrupoVida.sort((a, b) => a.lider.localeCompare(b.lider))
        setGruposVida(listaGrupoVida)
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true)
            } else {
                setUserLoged(false)
            }
        }) 
        GetDados()
    }, [busca])

    return (
            <>
                <Menu/>  
                <div className="container-fluid titulo"> 
                    <ul className="text-center">  
                        <h1>Lista de grupos de vida</h1> 
                    </ul>   
                    <div className="row">

                        <div className="col-12">
                            <div className="input-group mb-3">
                                <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar" aria-describedby="button-addon2" id='inputserach'/>
                                <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                            </div>
                        </div>
                    </div>  
                    <ListarGV arrayGruposVida={gruposvida} />
                    {
                        userIsLoged === false ? <Navigate to="/" replace={true} /> : null
                    }        
                </div>        
           </>
           )
}

export default ReuniaoGrupoVida