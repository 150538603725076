import React from 'react'
import { Link } from 'react-router-dom'
import './listareunioes.css'

function ListaReunioes(props) {
    return (
        <table className="table table-hover table-bordered">
            <thead>
            <tr className="table-secondary">
                <th scope="col">Tema</th>
                {/*<th scope="col">Morada</th>*/}
                <th scope="col">Data</th>
                <th scope="col">Presentes</th>
                <th scope="col" className="col-acao"></th>
            </tr>
        </thead>
        <tbody>
            {
                props.arrayReunioesGV.map((reunioesgv) => {
                    return (
                        <tr key={reunioesgv.id}>
                            <td>{reunioesgv.theme}</td>
                            {/*<td>{reunioesgv.adrress}</td>*/}
                            <td>{reunioesgv.data}</td>
                            <td>{reunioesgv.present}</td>
                            <td>
                                { reunioesgv.photo != '' ? <Link to={`/photogv/${reunioesgv.id}`}><i className="fas fa-camera icone-acao"></i></Link> : <i className="fas fa-camera icone-acao"></i>}
                                <Link to={`/inserirreuniao/${reunioesgv.id}/${reunioesgv.idlider}/${reunioesgv.adrress}/${reunioesgv.idgrupo}/${reunioesgv.data}`}><i className="fas fa-edit icone-acao"></i></Link>  
                                <Link to={`/consultareuniaoes/${reunioesgv.idgrupo}/${reunioesgv.idlider}/${reunioesgv.adrress}`} onClick={() => props.clickDelete(reunioesgv.id)}><i className="far fa-trash-alt icone-acao red"></i></Link>
                            </td>
                        </tr>
                    )
                })
            }
        </tbody>        
        </table>
    )
}

export default ListaReunioes