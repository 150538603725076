import React, { useEffect, useState }  from 'react'
import { Navigate, useParams } from 'react-router-dom'

import Formulario from './components/formulario'

import { auth } from '../../config/firebase'
import { onAuthStateChanged } from 'firebase/auth'

import Menu from '../../home/components/menu'

function Registragv() {

    const [userIsLoged, setUserLoged] = useState(true)
    const params = useParams()

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true)
            } else {
                setUserLoged(false)
            }
        }) 
    }, [])

    return (
        <div>
            <Menu/>
            {
                userIsLoged === false ? <Navigate to="/" replace={true} /> : null
            }              
            <Formulario id={params.id}/>
        </div>
    )
}

export default Registragv