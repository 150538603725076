import React from 'react'
import { Link } from 'react-router-dom'
import './listamensagemedit.css'

function ListaMensagensEdit(props) {
    
    return (
        <table className="table table-hover table-bordered">
            <thead>
            <tr className="table-secondary">
                <th scope="col">Título</th>
                <th scope="col">Data início</th>
                <th scope="col">Data fim</th>
                <th scope="col" className="col-md-1"></th>
            </tr>
        </thead>
        <tbody>
            {
                props.arrayMessagens.map((mensagens) => {
                    return (
                        <tr key={mensagens.id}>
                            <td>{mensagens.title}</td>
                            <td>{mensagens.iniWeek}</td>
                            <td>{mensagens.fimWeek}</td>
                            <td>
                                { !mensagens.readonly ? 
                                <>
                                    <Link to={`/viewmensagem/${mensagens.id}`}><i className="fas fa-folder-open icone-acao"></i></Link>
                                    <Link to={`/registromensagens/${mensagens.id}`}><i className="fas fa-edit icone-acao"></i></Link>
                                </> :
                                <Link to={`/viewmensagem/${mensagens.id}`}><i className="fas fa-folder-open icone-acao"></i></Link>
                                }
                                { !mensagens.readonly ? <Link to='/alterarmsggv' onClick={() => props.clickDelete(mensagens.id)}><i className="far fa-trash-alt icone-acao red"></i></Link> : null}
                            </td>
                        </tr>
                    )
                })
            }
        </tbody>        
        </table>
    )
}

export default ListaMensagensEdit