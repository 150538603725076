import React, { useState, useEffect, useRef } from 'react'
import { useParams, Link, Navigate } from 'react-router-dom'
import './formulario.css'
import { parse, format, getYear } from 'date-fns'

import Menu from '../../../home/components/menu'

import { db } from '../../../config/firebase'
import { collection, doc, getDoc, addDoc, updateDoc, serverTimestamp } from 'firebase/firestore'
import SweetAlert from 'react-bootstrap-sweetalert'
import { fazerUploadDaFoto } from '../../../common/funcoesuniversais'

function FormularioReunioesFeita() {
    // const videoRef = useRef(null)
    const [botaoDesativado, setBotaoDesativado] = useState(false);
    const params = useParams()
    const [idlider, setidLider] = useState('')
    const [theme, setTheme] = useState('')
    const [adrress, setAdrress] = useState('')
    const [data, setData] = useState('')
    const [idgrupo, setIdGrupo] = useState('')
    const [present, setPresent] = useState('') 
    const [presentnames, setPresentNames] = useState('')  
    const [obs, setObs] = useState('')
    const [photo, setPhoto] = useState('') 
    const [changePhoto, setChangePhoto] = useState(false)
    const [sucesso, setSucesso] = useState('')
    const [confirmacao, setConfirmacao] = useState(false)
    const [mensagem, setMensagem] = useState('')

    function getWeekNumber( date ){
        return parseInt(format(date, 'w'), 10);
    }

    async function getData(id) {
    
        const docRef = doc(db, 'groupmeetings', id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {

            const dataObjeto = parse(params.data, 'dd-MM-yyyy', new Date())
            const dataFormatada = format(dataObjeto, 'yyy-MM-dd')
            setTheme(docSnap.data().theme)
            setData(dataFormatada)
            setPresent(docSnap.data().present)
            setPresentNames(docSnap.data().presentnames)
            setObs(docSnap.data().obs)
            setPhoto(docSnap.data().photo)
        }        
    }

    const selecionarArquivo = async (event) => {
        const file = event.target.files[0];
        
        if (file) {
          setPhoto(file)
          if (params.id != 'novo') {
            setChangePhoto(true)
          }
        }
    }

    async function handlesubmit() {
        if (!theme || !data || !present){
            setMensagem('* Informe todos os campos obrigatórios')
            return
        }
        
        try {
            setBotaoDesativado(true);
            let urlImg
            if (photo != '') {
                urlImg = await fazerUploadDaFoto(photo, `${idgrupo}_${data}`)
                //console.log(urlImg)
            } else {
                urlImg = ''
            }
            if (params.id === 'novo') {   
                const dataObjeto = parse(data, 'yyyy-MM-dd', new Date())
                await addDoc(collection(db, 'groupmeetings'), {
                    idlider: idlider,
                    theme: theme,
                    adrress: adrress,
                    data: dataObjeto,
                    idgrupo: idgrupo,
                    present: present,
                    presentnames: presentnames,
                    obs: obs,
                    photo: urlImg,
                    year: getYear(data),
                    week: getWeekNumber(data),
                    created_at: serverTimestamp(),
                    updated_at: serverTimestamp()
                })
            } else {                
                changePhoto != true ? urlImg = photo : null 
                const dataObjeto = parse(data, 'yyyy-MM-dd', new Date())
                const docRef = doc(db, 'groupmeetings', params.id)
                await updateDoc(docRef, {
                    idlider: idlider,
                    theme: theme,
                    adrress: adrress,
                    data: dataObjeto,
                    idgrupo: idgrupo,
                    present: present,
                    presentnames: presentnames,
                    obs: obs,
                    photo: urlImg,
                    year: getYear(data),
                    week: getWeekNumber(data),
                    updated_at: serverTimestamp()       
                })
            }
            setSucesso('S') 
            setBotaoDesativado(false);
        } catch(erro) {
            setSucesso('N')
            console.error("Error adding document: ", erro)
            setBotaoDesativado(false);
        }
    }

    useEffect(() => {
        setidLider(params.idlider)
        setIdGrupo(params.idgrupo)
        setAdrress(params.adrress)
        if (params.id != 'novo') {
            getData(params.id)
        }
    }, [])    

    return(
        <>
            <Menu/>
            <div className="offset-lg-3 col-lg-6">
            <div>
                <ul className="text-center">
                    <h1>Registro de reuniões de grupo de vida</h1>
                </ul>
            </div>
                <form id="myform">
                    <div className="container-fluid align-self-center">
                        <div className="form-group mb-3">
                            <label htmlFor="inputtheme"><i className="fas fa-file"></i> Tema</label> <label htmlFor="inputtheme" style={{color: 'red'}} >*</label>
                            <input defaultValue={theme} onChange={(e) => setTheme(e.target.value)} type="text" className="form-control" placeholder="Tema" id='inputtheme' required/>
                        </div>
                        <div className="form-group mb-3">
                        <label htmlFor="inputdata"><i className="fas fa-calendar"></i> Data</label> <label htmlFor="inputdata" style={{color: 'red'}} >*</label>
                            <input defaultValue={data} onChange={(e) => setData(e.target.value)} type="date" className="form-control" placeholder="Data" id='inputdata' required/>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="inputpresent"><i className="fas fa-users"></i> Número de Presentes</label> <label htmlFor="inputpresent" style={{color: 'red'}} >*</label>
                            <input defaultValue={present} onChange={(e) => setPresent(e.target.value)} type="number" className="form-control" placeholder="Presentes" id='inputpresent' required/>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="textareapresents"><i className="fas fa-user-friends"></i> Nome de pessoas presentes</label>
                            <textarea defaultValue={presentnames} onChange={(e) => setPresentNames(e.target.value)} className="form-control" id="textareapresents" rows="3"></textarea>
                        </div>
                        <div className="custom-file">
                            <input accept="image/*" onChange={selecionarArquivo} className="custom-file-input" type='file' id='inpuphoto'/>
                            <label htmlFor='inpuphoto'>Selecionar foto...</label>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="inputobs"><i className="fas fa-asterisk"></i> Observações</label>
                            <textarea defaultValue={obs} onChange={(e) => setObs(e.target.value)} className="form-control" id="inputobs" rows="5"></textarea>
                        </div>
                        <div className="container-fluid">
                            {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert"><label>{mensagem}</label></div> : null}
                        </div>
                        <div className="d-flex justify-content-center">
                            <Link to={`/consultareuniaoes/${params.idgrupo}/${params.lider}/${params.adrress}`} className="w-50 btn btn-danger btn-acao" type="button"><i className="fas"></i> Cancelar</Link>
                            <button onClick={handlesubmit} type="button" className="w-50 btn btn-primary btn-acao" disabled={botaoDesativado}>Guardar</button>
                            {
                                sucesso === 'S' ? <SweetAlert
                                                    success
                                                    title="Success!"
                                                    onConfirm={() => setConfirmacao(true)}
                                                    onCancel={() => setSucesso('N')}
                                                    timeout={2000}
                                                >
                                                    Registro gravado com sucesso!
                                                </SweetAlert> : null
                            }
                            {
                                confirmacao ? <Navigate to={`/inserirreuniaosgv`} replace={true} /> : null
                            }
                        </div>   
                    </div>
                </form>
            </div>
        </>
    )
}

export default FormularioReunioesFeita