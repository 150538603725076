import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { format, startOfWeek, eachWeekOfInterval } from 'date-fns'
import localePt from 'date-fns/locale/pt'
import './index.css'
import Menu from '../../home/components/menu'
import ListaRelatorio from './components/listarelatorio'
import GraficoLinhas from './components/graficolinhas'

import { auth } from '../../config/firebase'
import { onAuthStateChanged } from 'firebase/auth'

import { db } from '../../config/firebase'
import { collectionGroup, collection, getDocs, orderBy, query, where, and } from 'firebase/firestore'
import { getNameLider } from '../../common/funcoesuniversais'

function RelatorioGV() {

    const [idLider, setIdLider] = useState('')
    const [reunioes, setReunioes] = useState([])
    const [reunioesGrafico, setReunioesGrafico] = useState('')
    const [reunioesGraficoWeek, setReunioesGraficoWeek] = useState('')
    const [userIsLoged, setUserLoged] = useState(true)
    const [dataIni, setDataIni] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [mostrarGrafico, setMostrarGrafico] = useState(false)

    const [options, setOptions] = useState([])

    const obterNumerosDasSemanas = (dataInicial, dataFinal) => {
        const intervaloDeDatas = eachWeekOfInterval({
          start: new Date(dataInicial),
          end: new Date(dataFinal),
        })
      
        const numerosDasSemanas = intervaloDeDatas.map((data) => {
          const primeiraDataDaSemana = startOfWeek(data)
          return parseInt(format(primeiraDataDaSemana, 'w'), 10)
        })
        return numerosDasSemanas
    }
      
    const numerosDasSemanas = obterNumerosDasSemanas(dataIni, dataFim);
      
    async function getRelGV() {

        const listaReunioes = []

        const q = query(collectionGroup(db, 'groupmeetings'), 
                        and(where('week', 'in', numerosDasSemanas), 
                        where('idlider', '==', idLider)),
                        orderBy('week'),
                        orderBy('idlider'),
                        orderBy('idgrupo'),
                        orderBy('present')
                        )
        const querySnapshot = await getDocs(q)
        await Promise.all(querySnapshot.docs.map(async (doc) => {
            const idLider = doc.data().idlider
            const nameLider = await getNameLider(idLider)
            const dataFormatada = format(new Date(doc.data().data.seconds * 1000), 'dd-MM-yyyy', { locale: localePt, })
            listaReunioes.push({
                id: doc.id,
                idgrupo: doc.data().idgrupo,
                idlider: doc.data().idlider,
                lider: nameLider,
                present: doc.data().present,
                data: dataFormatada,
                week: doc.data().week
            })
        }))
        listaReunioes.sort((a, b) => a.lider.localeCompare(b.lider))
        setReunioes(listaReunioes)

        const semanas = new Set()
        const dadosAgrupados = listaReunioes.reduce((agrupado, atual) => {
            const { lider, present, week } = atual
            
            // Verifique se o líder já está no objeto agrupado
            if (!agrupado[lider]) {
              agrupado[lider] = { name: lider, data: [] }
            }

            semanas.add(week)
            
            // Adicione a quantidade de presentes ao array de dados
            agrupado[lider].data.push(present)
            return agrupado
        }, {})

        const dadosFormatados = Object.values(dadosAgrupados)

        const arraySemanas = Array.from(semanas).sort((a, b) => a - b)

        setReunioesGraficoWeek(arraySemanas)

        setReunioesGrafico(dadosFormatados)

        setMostrarGrafico(true)
    }

    async function getLiderNames() {
        // const listaLideres = []

        const optionsData = []
        const q = query(collection(db, 'users'), where('liderGV', '==', true))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) =>{
            optionsData.push({ id: doc.id, ...doc.data() })
        })
        optionsData.sort((a, b) => a.name.localeCompare(b.name))
        setOptions(optionsData)
    }

    function primeiroUltimoDiaDoMes() {
        const hoje = new Date()
        const primeiroDiaDoMes = new Date(hoje.getFullYear(), hoje.getMonth(), 1)
        const ultimoDiaDoMes = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0)
      
        const formatarData = (data) => {
            const ano = data.getFullYear();
            const mes = (data.getMonth() + 1).toString().padStart(2, '0') // adiciona zero à esquerda se necessário
            const dia = data.getDate().toString().padStart(2, '0') // adiciona zero à esquerda se necessário
            return `${ano}-${mes}-${dia}`
        }

        return {
          primeiroDia: formatarData(primeiroDiaDoMes),
          ultimoDia: formatarData(ultimoDiaDoMes)
        }
    }

    useEffect(() => {
        getLiderNames()
    }, [dataIni, dataFim])

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const { primeiroDia, ultimoDia } = primeiroUltimoDiaDoMes()
                setUserLoged(true)
                setDataIni(primeiroDia)
                setDataFim(ultimoDia)
                //getRelGV()
            } else {
                setUserLoged(false)
            }
        }) 
    }, [])

    return (
            <>
                <Menu/>
                <div className="container-fluid titulo">  
                    <ul className="text-center"> 
                        <h1>Relatório de grupos de vida</h1> 
                    </ul>   
                    <div className="row">
                      <div className="col-12">
                            <label className='align-self-center' htmlFor='inputinidate'>Selecionar data para relatório</label>
                            <div className="input-group mb-3">
                                <input defaultValue={dataIni} onChange={(e) => setDataIni(e.target.value)} type="date" className="form-control inpdate" placeholder="Pesquisar" aria-describedby="button-addon2" id='inputinidate'/>
                                <input defaultValue={dataFim} onChange={(e) => setDataFim(e.target.value)} type="date" className="form-control inpdate" placeholder="Pesquisar" aria-describedby="button-addon2" id='inputfimdate'/>
                            </div>
                            <div className="input-group mb-3">
                                <label htmlFor="selectlider"><i className="fas fa-user-tie"></i> Líder do grupo</label>
                            </div>
                            <div className="input-group mb-3">
                               <select className="form-control" id="selectlider" value={idLider} onChange={(e) => setIdLider(e.target.value)}>
                                    <option value="">Selecionar líder...</option>
                                    {options.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name} {/* Substitua 'nome' pelo campo desejado no documento */}
                                    </option>
                                    ))}
                                </select>
                                <button onClick={ !idLider == '' ? () => getRelGV() : null} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                            </div>
                        </div>
                    </div> 
                    { mostrarGrafico ?  <> 
                                            <GraficoLinhas arrayRel={reunioesGrafico} arrayRelWeek={reunioesGraficoWeek} /> 
                                            <ListaRelatorio arrayReunioes={reunioes} />
                                        </> : null}
                </div>
                {
                    userIsLoged === false ? <Navigate to="/" replace={true} /> : null
                }                
           </>
           )
}

export default RelatorioGV