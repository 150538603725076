import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import Menu from '../../home/components/menu'

import ListaMensagensEdit from '../../cadastro/mensagemgv/components/listamensagemedit'

import { parse, format } from 'date-fns'
import localePt from 'date-fns/locale/pt'

import { db, auth } from '../../config/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'

function ListarMensagens() {

    const [userIsLoged, setUserLoged] = useState(true)
    const [mensagens, setMensagens] = useState([])
    const [busca, setBusca] = useState('')
    const [texto, setTexto] = useState('')
    const [dataIni, setDataIni] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [dataIniFull, setDataIniFull] = useState('')
    
    async function GetMessage(allData) {
        const listaMessagens = []

        if (allData) {
            const dataObjetoIni = parse(dataIni, 'yyyy-MM-dd', new Date())    
            const dataObjetoFim = parse(dataFim, 'yyyy-MM-dd', new Date())   
  
            const q = query(collection(db, 'message'), 
                    where('iniWeek', '>=', dataObjetoIni), 
                    where('iniWeek', '<=', dataObjetoFim), 
                    orderBy('iniWeek', 'asc'))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc) => {
                const dataFormatadaIni = format(new Date(doc.data().iniWeek.seconds * 1000), "dd-MM-yyyy", { locale: localePt, })
                const dataFormatadaFim = format(new Date(doc.data().fimWeek.seconds * 1000), "dd-MM-yyyy", { locale: localePt, })
                listaMessagens.push({
                    id: doc.id,
                    title: doc.data().title,
                    iniWeek: dataFormatadaIni,
                    fimWeek: dataFormatadaFim,
                    readonly: true
                })
                setMensagens(listaMessagens)
            })
            setBusca('')
        } else {
            const q = query(collection(db, 'message'), orderBy('iniWeek', 'desc'))
            const querySnapshot = await getDocs(q)
            setBusca(texto)
            querySnapshot.forEach((doc) => {
                if (doc.data().title.indexOf(busca) >= 0) {
                    const dataFormatadaIni = format(new Date(doc.data().iniWeek.seconds * 1000), "dd-MM-yyyy", { locale: localePt, })
                    const dataFormatadaFim = format(new Date(doc.data().fimWeek.seconds * 1000), "dd-MM-yyyy", { locale: localePt, })
                    listaMessagens.push({
                        id: doc.id,
                        title: doc.data().title,
                        iniWeek: dataFormatadaIni,
                        fimWeek: dataFormatadaFim,
                        readonly: true
                    })
                }
                setMensagens(listaMessagens)
            })            
        }
    }

    function primeiroUltimoDiaDoMes() {
        const hoje = new Date()
        const primeiroDiaDoMes = new Date(hoje.getFullYear(), hoje.getMonth(), 1)
        const ultimoDiaDoMes = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0)
      
        const formatarData = (data) => {
            const ano = data.getFullYear();
            const mes = (data.getMonth() + 1).toString().padStart(2, '0') // adiciona zero à esquerda se necessário
            const dia = data.getDate().toString().padStart(2, '0') // adiciona zero à esquerda se necessário
            return `${ano}-${mes}-${dia}`
        }

        return {
          primeiroDia: formatarData(primeiroDiaDoMes),
          ultimoDia: formatarData(ultimoDiaDoMes),
          primeiroDiaDoMes,
          ultimoDiaDoMes
        }
    }   

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const { primeiroDia, ultimoDia, primeiroDiaDoMes } = primeiroUltimoDiaDoMes()
                setUserLoged(true)
                setDataIni(primeiroDia)
                setDataFim(ultimoDia)
                setDataIniFull(primeiroDiaDoMes)
            } else {
                setUserLoged(false)
            }
        }) 
    }, [])

    useEffect(() => {   
        if (busca != '') {
            GetMessage(false);
        }
    }, [busca])

    useEffect(() => {
        if (dataIniFull) {
            GetMessage(true);
        }
    }, [dataIniFull]);    

    return (
            <>
                <Menu/>
                <div className="container-fluid titulo">   
                <ul className="text-center">
                    <h1>Lista de mensagens GV</h1>  
                </ul>  
                <div className="row">
                    <div className="col-12">
                        <div className="input-group mb-3">
                            <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por título" aria-describedby="button-addon2" id='inputserach'/>
                            <button onClick={() => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                        </div>
                    </div>

                    <label className='align-self-center' htmlFor='inputinidate'>Selecionar data</label>
                    <div className="col-12">
                        <div className="input-group mb-3">
                            <input defaultValue={dataIni} onChange={(e) => setDataIni(e.target.value)} type="date" className="form-control inpdate" placeholder="Pesquisar" aria-describedby="button-addon2" id='inputinidate'/>
                            <input defaultValue={dataFim} onChange={(e) => setDataFim(e.target.value)} type="date" className="form-control inpdate" placeholder="Pesquisar" aria-describedby="button-addon2" id='inputfimdate'/>
                            <button onClick={() => GetMessage(true)} className="btn btn-primary" type="button" id="button-addon1"><i className="fas fa-search"></i> Pesquisar</button>
                        </div>   
                    </div>
                </div>
                <ListaMensagensEdit arrayMessagens={mensagens} />
                {
                    userIsLoged === false ? <Navigate to="/" replace={true} /> : null
                }                
                </div>
            </>
           )
}

export default ListarMensagens