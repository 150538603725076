import React, { useState, useEffect }  from 'react'
import { useParams, Navigate, Link } from 'react-router-dom'
import { parse, format } from 'date-fns'

import { db } from '../../../config/firebase'
import { collection, doc, getDoc, addDoc, updateDoc, serverTimestamp } from 'firebase/firestore'
import SweetAlert from 'react-bootstrap-sweetalert'

function FormularioColheita() {

  const params = useParams()
  const [idColheita, setIdColheita] = useState('')
  const [theme, setTheme] = useState('')
  const [day, setDay] = useState('')
  const [presents, setPresents] = useState('')
  const [obs, setObs] = useState('')
  const [sucesso, setSucesso] = useState('')
  const [confirmacao, setConfirmacao] = useState(false)
  const [mensagem, setMensagem] = useState('')

  async function handlesubmit() {
    if (!theme || !day || !presents){
      setMensagem('* Informe todos os campos obrigatórios')
      return
  }    
    try {
      if (!idColheita) {
        const dataObjeto = parse(day, 'yyyy-MM-dd', new Date())
        await addDoc(collection(db, 'colheita'), {
          theme: theme,
          day: dataObjeto,
          presents: presents,
          obs: obs,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        })
      } else {
        const docRef = doc(db, 'colheita', idColheita)
        const dataObjeto = parse(day, 'yyyy-MM-dd', new Date())
        await updateDoc(docRef, {
          theme: theme,
          day: dataObjeto,
          presents: presents,
          obs: obs,
          updated_at: serverTimestamp(),               
      })
      }
      setSucesso('S') 
    } catch(err) {
      setSucesso('N')
      console.error("Error adding document: ", err)
    }
  }

  async function getData(id) {
    const docRef = doc(db, 'colheita', id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const dataFormatada = format(docSnap.data().day.seconds* 1000, 'yyy-MM-dd')
      setIdColheita(docSnap.id)
      setDay(dataFormatada)
      setTheme(docSnap.data().theme)
      setPresents(docSnap.data().presents)
      setObs(docSnap.data().obs)
    }
  }
  useEffect(() => {
    if (params.id) {
        getData(params.id)
    }
  }, []) 

  return (
    <div className="offset-lg-3 col-lg-6">
      <div>
          <ul className="text-center">
              <h1>Registro dia do Encontro</h1>
          </ul>
      </div>
      <form id="myform">
        <div className="container-fluid align-self-center">
          <div className="mb-3">
            <label htmlFor="inputtheme"><i className="fas fa-file"></i> Tema</label> <label htmlFor="inputtheme" style={{color: 'red'}} >*</label>
            <input defaultValue={theme} onChange={(e) => setTheme(e.target.value)} type="text" className="form-control" placeholder="Tema" id='inputtheme' required/>
          </div>
          <div className="form-group mb-3">
            <label htmlFor="inputdata"><i className="fas fa-calendar"></i> Data</label> <label htmlFor="inputdata" style={{color: 'red'}} >*</label>
            <input defaultValue={day} onChange={(e) => setDay(e.target.value)} type="date" className="form-control" placeholder="Data" id='inputdata' required/>
          </div>  
          <div className="form-group mb-3">
              <label htmlFor="inputpresent"><i className="fas fa-users"></i> Número de Presentes</label> <label htmlFor="inputpresent" style={{color: 'red'}} >*</label>
              <input defaultValue={presents} onChange={(e) => setPresents(e.target.value)} type="number" className="form-control" placeholder="Presentes" id='inputpresent' required/>
          </div>  
          <div className="form-group mb-3">
              <label htmlFor="inputobs"><i className="fas fa-asterisk"></i> Observações</label>
              <textarea defaultValue={obs} onChange={(e) => setObs(e.target.value)} className="form-control" id="inputobs" rows="5"></textarea>
          </div>  
          <div className="container-fluid">
              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert"><label>{mensagem}</label></div> : null}
          </div>   
          <div className="d-flex justify-content-center">
              <Link to={`/colheita`} className="w-50 btn btn-danger btn-acao" type="button"><i className="fas"></i> Cancelar</Link>
              <button onClick={handlesubmit} type="button" className="w-50 btn btn-primary btn-acao">Guardar</button>
              {
                  sucesso === 'S' ? <SweetAlert
                                      success
                                      title="Success!"
                                      onConfirm={() => setConfirmacao(true)}
                                      onCancel={() => setSucesso('N')}
                                      timeout={2000}
                                  >
                                      Registro gravado com sucesso!
                                  </SweetAlert> : null
              }
              {
                  confirmacao ? <Navigate to={`/colheita`} replace={true} /> : null
              }
          </div>                                
        </div>
      </form>
    </div>
  )
}

export default FormularioColheita