import { auth, db, storage } from '../config/firebase'
import { onAuthStateChanged, createUserWithEmailAndPassword } from 'firebase/auth'
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { format } from 'date-fns'
import localePt from 'date-fns/locale/pt'
import Globais from '../home/components/globais'

export function userLoged() {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            console.log('oi')
            return true
        } else {
            return false
        }
    })
}

export function dataUserLoged() {
    try {
        const usersArray = []
        usersArray.push({
            userId: localStorage.getItem('userId'),
            email: localStorage.getItem('email'),
            userName: localStorage.getItem('userName'),
            userAdm: localStorage.getItem('userAdm')
        });
        return usersArray
    } catch (error) {
        console.error('Erro ao obter dados do localStorage:', error)
    }
}

export async function setuserInformation(email) {
    const q = query(collection(db, "users"), where("email", "==", email))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
        localStorage.setItem('userId', doc.id)
        localStorage.setItem('email', doc.data().email)
        localStorage.setItem('userName', doc.data().name)
        localStorage.setItem('userAdm', doc.data().adm)
        localStorage.setItem('userLiderGV', doc.data().liderGV)
        localStorage.setItem('userMinisteryLider', doc.data().ministeryLider)
        localStorage.setItem('userWorshipMinistry', doc.data().worshipMinistry)
        Globais.userAdm = doc.data().adm
    })
}

export function userIsAdministrator() {
    if (localStorage.getItem('userAdm')) {
        return true
    } else {
        return false
    }
}

export async function createUser(email) {
    let id
    await createUserWithEmailAndPassword(auth, email, 'ibn123456')
    .then((userCredential) => {
        id = userCredential.user.uid
    })
    .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log(errorCode + ' ' + errorMessage)
      })
    return id  
}

export async function getLiderRelGV(numerosDasSemanas) {
    const listaReunioes = []

        const q = query(collectionGroup(db, 'groupmeetings'), 
                        where('week', 'in', numerosDasSemanas), 
                        //where('data', '<=', new Date(dataFim)),
                        orderBy('week'),
                        orderBy('lider'),
                        orderBy('idgrupo'),
                        orderBy('present')
                        )
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
            const dataFormatada = format(new Date(doc.data().data.seconds * 1000), "dd-MM-yyyy", { locale: localePt, })
            listaReunioes.push({
                idlider: doc.data().idlider
            })
            return listaReunioes
        })
}

export async function getNameLider(id) {
    const docRef = doc(db, 'users', id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        return docSnap.data().name
    }
}

export async function fazerUploadDaFoto(file, nemefile) {
    // Referência para o local no Storage onde deseja armazenar a foto
    const storageRef = ref(storage, `gv/reunioes/${nemefile}.jpg`)
    // Fazer o upload da foto
    await uploadBytes(storageRef, file)
    // Obter o URL de download da foto
    const downloadURL = await getDownloadURL(storageRef)
    //console.log('Foto enviada com sucesso. URL de download:', downloadURL)
    return {
        path: storageRef._location.path,
        url: downloadURL
    }
}

export async function obterURLDaFoto(path) {
    // Referência para o local no Storage onde a foto está armazenada
    const storageRef = ref(storage, path)
    // Obter o URL de download da foto
    const downloadURL = await getDownloadURL(storageRef)

    console.log('URL de download da foto:', downloadURL)
    return downloadURL
}