import React from 'react'

function ListaRelatorio(props) {
    return (
        <>
        <table className="table table-hover table-bordered">
        <thead>
            <tr className="table-secondary">
                {/*<th scope="col">Líder</th>*/}
                <th scope="col">Data</th>
                <th scope="col">Semana</th>
                <th scope="col">Presentes</th>
            </tr>
        </thead>
        <tbody>
            {
                props.arrayReunioes.map((reunioes) => {
                    return (
                        <tr key={reunioes.id}>
                            {/*<td>{reunioes.lider}</td>*/}
                            <td>{reunioes.data}</td>
                            <td>{reunioes.week}</td>
                            <td>{reunioes.present}</td>
                        </tr>
                    )
                })
            }
        </tbody>
        </table>        
        </>
    )
}

export default ListaRelatorio