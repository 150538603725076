import React, { useEffect, useState } from 'react'
import Menu from '../home/components/menu'
import { Navigate } from 'react-router-dom'
import './index.css'
import { auth } from '../config/firebase'
import { onAuthStateChanged } from 'firebase/auth'

function AdministracaoSite() {

    const [userIsLoged, setUserLoged] = useState(true)

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true)
            } else {
                setUserLoged(false)
            }
        }) 
    }, [])

    return(
        <>
        <Menu/>
        <div className="container">
            <ul className="text-center">
                <h1>Área de administração</h1>
            </ul>
        </div>
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3 container-fluid">
            <div className="col d-flex align-items-start">
                <div>
                    <h2>Início</h2>
                    <p>Para alterar o texto da página inicial</p>
                    <a href="/alterhome" className="btn btn-primary">
                        Editar
                    </a>
                </div>
            </div>
            <div className="col d-flex align-items-start">
                <div>
                    <h2>Mensagens grupo de vida</h2>
                    <p>Alterar e inserir novas mensagens para o grupo de vida</p>
                    <a href="/alterarmsggv" className="btn btn-primary">
                        Editar
                    </a>
                </div>
            </div>
            <div className="col d-flex align-items-start">
                <div>
                    <h2>Adicionar membro</h2>
                    <p>Alterar e inserir membros</p>
                    <a href="/consultamembros" className="btn btn-primary">
                        Editar
                    </a>
                </div>
            </div>
            <div className="col d-flex align-items-start">
                <div>
                    <h2>Grupos de vida</h2>
                    <p>Alterar e inserir grupos de vida</p>
                    <a href="/consultagv" className="btn btn-primary">
                        Editar
                    </a>
                </div>
            </div>            
            <div className="col d-flex align-items-start">
                <div>
                    <h2>Reuniões</h2>
                    <p>Mapa de reuniões de grupo de vida.</p>
                    <a href="/relatorioreuniaogv" className="btn btn-primary">
                        Editar
                    </a>
                </div>
            </div>
            <div className="col d-flex align-items-start">
                <div>
                    <h2>Dia do Encontro</h2>
                    <p>Para adicionar dia do Encontro.</p>
                    <a href="/colheita" className="btn btn-primary">
                        Editar
                    </a>
                </div>
            </div>            
        </div>
        {
            userIsLoged === false ? <Navigate to="/" replace={true} /> : null
        }  
    </>
    )
}

export default AdministracaoSite