// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
//import { getAnalytics } from 'firebase/analytics'
import { getStorage } from 'firebase/storage'

/*const apiKey = import.meta.env.VITE_FIREBASE_API_KEY
const authDomain = import.meta.env.VITE_FIREBASE_AUTHDOMAIN
const projectId = import.meta.env.VITE_FIREBASE_PROJECTID
const storageBucket = import.meta.env.VITE_FIREBASE_STORAGEBUCKET
const messagingSenderId = import.meta.env.VITE_FIREBASE_MESSAGINGSENDERID
const appId = import.meta.env.VITE_FIREBASE_APPID
const measurementId = import.meta.env.VITE_FIREBASE_MEASUREMENTID */

// Your web app's Firebase configuration
//Meu
// const firebaseConfig = {
//   apiKey: "AIzaSyDdaJ3Ht9OH3qAEStUYweyZrL3fl5bqtls",
//   authDomain: "boasnovas-b4130.firebaseapp.com",
//   projectId: "boasnovas-b4130",
//   storageBucket: "boasnovas-b4130.appspot.com",
//   messagingSenderId: "475314323684",
//   appId: "1:475314323684:web:2b4ac1a7603d1908132cf1",
//   measurementId: "G-C0M0V45BR5"
// }

//Produção
const firebaseConfig = {
  apiKey: "AIzaSyA6-SCux7gx4ibvOF1zLFBvw5gd8S-9R_U",
  authDomain: "igrejaboasnovas-b6cfc.firebaseapp.com",
  projectId: "igrejaboasnovas-b6cfc",
  storageBucket: "igrejaboasnovas-b6cfc.appspot.com",
  messagingSenderId: "770801868836",
  appId: "1:770801868836:web:66c53b565ab5aec39338b1",
  measurementId: "G-NJV6N514BW"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
export const db = getFirestore(app)
//export const analytics = getAnalytics(app)
export const storage = getStorage(app)
export default app
