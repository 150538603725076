import React, { useEffect, useState } from 'react'
import {
    createBrowserRouter,
    RouterProvider
  } from 'react-router-dom'

import { auth } from '../src/config/firebase'
import { onAuthStateChanged } from 'firebase/auth'

//import Menu from './home/components/menu'
import Rodape from './home/components/rodape'
/** Páginas */
import ErrorPage from "./error-page"
import Login from './login'
import Home from './home'
import ResetSenha from './resetpwd'
import ListarMensagens from './consultas/mensagens'
import AdministracaoSite from './adm'
import ReuniaoGrupoVida from './cadastro/reuniaogv/reuniaogv'
import RegistrarMembros from './cadastro/membro/membro'
import MensagemGV from './cadastro/mensagemgv'
import EditarHome from './cadastro/home'
import RelatorioGV from './rel/reuniaogv'
import ConsultaMembros from './consultas/membros'
import FormularioMsgGv from './cadastro/mensagemgv/components/formulario'
import VisualizaMensagem from './consultas/mensagens/components/visualizarmensagem'
import Registrargv from './cadastro/grupovida/grupovida'
import ConsultaGV from './consultas/grupovida'
import ReuniaoesFeitas from './cadastro/reuniaogv/reuniaofeitas'
import FormularioReunioesFeita from './cadastro/reuniaogv/components/formulario'
import PhotoGV from './cadastro/reuniaogv/components/photogv'
import CadastroColheita from './cadastro/colheita'
import ConsultaColheita from './consultas/colheita'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/resetsenha",
    element: <ResetSenha />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/mensagensgv",
    element: <ListarMensagens />,
    errorElement: <ErrorPage />,
  },  
  {
    path: "/adm",
    element: <AdministracaoSite />,
    errorElement: <ErrorPage />,
  },   
  {
    path: "/inserirreuniaosgv",
    element: <ReuniaoGrupoVida />,
    errorElement: <ErrorPage />,
  }, 
  {
    path: "/alterarmembros/:id",
    element: <RegistrarMembros />,
    errorElement: <ErrorPage />,
  },  
  {
    path: "/alterarmembros",
    element: <RegistrarMembros />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "/alterarmsggv",
    element: <MensagemGV />,
    errorElement: <ErrorPage />,
  },      
  {
    path: "/alterhome",
    element: <EditarHome />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "/relatorioreuniaogv",
    element: <RelatorioGV />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "/consultamembros",
    element: <ConsultaMembros />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "/registromensagens/:id",
    element: <FormularioMsgGv />,
    errorElement: <ErrorPage />,
  },   
  {
    path: "/registromensagens",
    element: <FormularioMsgGv />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/viewmensagem/:id",
    element: <VisualizaMensagem />,
    errorElement: <ErrorPage />,
  },      
  {
    path: "/inserirgv/:id",
    element: <Registrargv />,
    errorElement: <ErrorPage />,
  },     
  {
    path: "/inserirgv",
    element: <Registrargv />,
    errorElement: <ErrorPage />,
  },   
  {
    path: "/consultagv",
    element: <ConsultaGV />,
    errorElement: <ErrorPage />,
  },   
  {
    path: "/consultareuniaoes",
    element: <ReuniaoesFeitas />,
    errorElement: <ErrorPage />,
  },   
  {
    path: "/consultareuniaoes/:id/:idlider/:adrress",
    element: <ReuniaoesFeitas />,
    errorElement: <ErrorPage />,
  },     
  {
    path: "/inserirreuniao",
    element: <FormularioReunioesFeita />,
    errorElement: <ErrorPage />,
  },   
  {
    path: "/inserirreuniao/:id/:idlider/:adrress/:idgrupo/:data",
    element: <FormularioReunioesFeita />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "/photogv/:id",
    element: <PhotoGV />,
    errorElement: <ErrorPage />,
  },    
  {
    path: "/colheita",
    element: <ConsultaColheita />,
    errorElement: <ErrorPage />,
  },   
  {
    path: "/inserircolheita",
    element: <CadastroColheita/>,
    errorElement: <ErrorPage/>,
  },  
  {
    path: "/inserircolheita/:id",
    element: <CadastroColheita/>,
    errorElement: <ErrorPage/>,
  },   
]);

function App () {

    const [userLoged, setUserLoged] = useState(true)

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true);
            } else {
                setUserLoged(false);
            }
        })  
    }, []);  

    return (
        <>
            { 
            //window.location.pathname === '/' ? null : <Menu /> 
            }
            <RouterProvider router={router} forceRefresh={!('pushState' in window.history)} />
            <Rodape />
        </>
    )
}

export default App;