import React from 'react'
import { Link } from 'react-router-dom'
import './listagruposvida.css';

function ListarGV(props) {
    return(
        <table className="table table-hover table-bordered">
            <thead>
            <tr className="table-secondary">
                <th scope="col">Lider</th>
                <th scope="col">Morada</th>
                <th scope="col">Dia</th>
                <th scope="col" className="col-acao2"></th>
            </tr>
        </thead>
        <tbody>
            {
                props.arrayGruposVida.map((gruposvida) => {
                    return (
                        <tr key={gruposvida.id}>
                            <td>{gruposvida.lider}</td>
                            <td>{gruposvida.adrress}</td>
                            <td>{gruposvida.day}</td>
                            <td>
                                { 
                                    !gruposvida.meeting ? <Link to={`/inserirgv/${gruposvida.id}`}><i className="fas fa-edit icone-acao2"></i></Link> : 
                                    <Link to={`/consultareuniaoes/${gruposvida.id}/${gruposvida.idlider}/${gruposvida.adrress}`}><i className="fas fa-folder-open icone-acao2"></i></Link>  
                                }
                                { 
                                    !gruposvida.meeting ? <Link to='/consultagv' onClick={() => props.clickDelete(gruposvida.id)}><i className="far fa-trash-alt icone-acao2 red"></i></Link> : 
                                    null
                                }
                            </td>
                        </tr>
                    )
                })
            }
        </tbody>        
        </table>
    )
}

export default ListarGV