import React from 'react'

function Rodape() {
  var ano = new Date().getFullYear();

  return (
    <section id="footer">
      <div>
        <ul className="list-unstyled list-inline social text-center">
          <li className="list-inline-item"><a href="https://facebook.com/ibn.porto/"><i className="fa fa-facebook fa-2x"></i></a></li>
          <li className="list-inline-item"><a href="https://instagram.com/boasnovas.church/"><i className="fa fa-instagram fa-2x"></i></a></li>
          <li className="list-inline-item"><a href="https://www.youtube.com/@boasnovas.church/"><i className="fa fa-youtube fa-2x"></i></a></li>
          <li className="list-inline-item"><a href="mailto:ibn@hotmail.com" target="_blank"><i className="fa fa-envelope fa-2x"></i></a></li>
        </ul>
      </div>
      <a className='mb-1' href="mailto:ibn@hotmail.com">ibn@hotmail.com</a>
      <p className='mb-1'>{ano} © Igreja Boas Novas - Todos os direitos reservados</p>
      <p className='mb-1'>Desenvolvido por Adriano Toledo{/*<a href="https://adrianotoledo.site" target="_blank">Adriano Toledo</a>*/}</p>
    </section>);
}

export default Rodape