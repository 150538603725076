import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Menu from '../../../home/components/menu'
import { db } from '../../../config/firebase'
import { doc, getDoc } from 'firebase/firestore'

function PhotoGV() {
  const params = useParams()
  const [urlImg, setUrlImg] = useState()

  async function getDados(id) {
    const docRef = doc(db, 'groupmeetings', params.id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      setUrlImg(docSnap.data().photo.url)
    }
  }

  useEffect(() => {
    getDados()
  }, [])

  return (
    <>
      <Menu/>
      <div className="container mt-3">
        <div className="row">
            <div className="col">
            <div className="card">
                <div className="card-body">
                  <img src={urlImg} className="img-fluid" alt='Foto Grupo de Vida' />
                </div>
            </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default PhotoGV