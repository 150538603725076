import React , { useState, useEffect } from 'react'
import './index.css'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import { setuserInformation } from '../common/funcoesuniversais'
import inblogo from '../assets/img/LOGO_CINZA.jpg'
import LoadingSpinner from '../common/loading'

import { auth } from '../config/firebase'
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'

function Login(){

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  function isLogedUser() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setuserInformation(user?.email)
        navigate('/home', { replace: false })
      }
    })
  }

  useEffect(()=> {
    setIsLoading(true)
    isLogedUser()
    setIsLoading(false)
  }, []);   

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [sucesso, setSucesso] = useState()

  function LoginUsuario (){
    setIsLoading(true)
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
    // Signed up 
    const user = userCredential.user
    setSucesso('S')
    setIsLoading(false)
    })
    .catch(function(error){
      setIsLoading(false)
      setSucesso('N')
    })
    setuserInformation(email)
  }
  
  return <div className="d-flex align-items-center text-center form-container">
    <form className="form-signin">
      <img className="imglogin mb-4" src={inblogo} alt="" />
      <h1 className="h3 mb-3 fw-normal">Grupo de Vida</h1>

      <div className="form-floating">
        <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="floatingInput" placeholder="E-mail" data-test="inpUserEmail"/>
        <label htmlFor="floatingInput">E-mail</label>
      </div>

      <div className="form-floating">
        <input onChange={(e)=> setPassword(e.target.value)} type="password" className="form-control" id="floatingPassword" placeholder="Senha" data-test="inpPassword"/>
        <label htmlFor="floatingPassword">Senha</label>
      </div>
      
      <button onClick={LoginUsuario} className="w-100 btn btn-lg btn-primary" type="button" data-test="btnLogin">Acessar</button>
      
      { // sucesso === 1 ? 'faça isso' : 'faça aquilo'
      sucesso === 'N' ?
      <div className="alert alert-danger" role="alert">E-mail ou senha invalido!</div> : null
      }
      {
        //sucesso === 'S' ? <Navigate to="/home" replace={true} /> : null
        sucesso === 'S' ? navigate('/home', { replace: false }) : null
      }
      <div className="login-links mt-5">
        <NavLink to="/resetsenha" className="mx-3">Esqueci minha senha</NavLink>
      </div>
      {isLoading ? (
          <LoadingSpinner />
        ) : null 
      } 
    </form>
  </div>
}

export default Login