import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Menu from '../../home/components/menu'
import { Navigate } from 'react-router-dom'

import { db, auth } from '../../config/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { collection, getDocs, orderBy, doc, deleteDoc, query } from 'firebase/firestore'

import ListaMembros from './components/listamembros'
import SweetAlert from 'react-bootstrap-sweetalert'

function ConsultaMembros() {

    const [userIsLoged, setUserLoged] = useState(true)
    const [membros, setMembros] = useState([])
    const [busca, setBusca] = useState('')
    const [texto, setTexto] = useState('')
    const [excluido, setExcluido] = useState('')
    const [confirmacao, setConfirmacao] = useState(false)
    const [confirmacaoId, setConfirmacaoId] = useState('')

    async function deleteUser(id){
        await deleteDoc(doc(db, 'users', id))
          setExcluido(id)
          setConfirmacao(false)
    }
  
    function confirmDeleteUser(id){
      setConfirmacaoId(id)
      setConfirmacao(true)
    }      

    async function getMembros() {
        const listaMembros = []

        const q = query(collection(db, 'users'), orderBy('name'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
            if (doc.data().name.indexOf(busca) >= 0) {
                listaMembros.push({
                    id: doc.id,
                    name: doc.data().name,
                    phone: doc.data().phone,
                    liderGV: doc.data().liderGV
                })
            }
            setMembros(listaMembros)
        })
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserLoged(true)
            } else {
                setUserLoged(false)
            }
        }) 
    }, [])

    useEffect(() => {
        getMembros()
    }, [busca, excluido])

    return (
        <>
            <Menu/>
            <div className="container-fluid titulo">   
                <ul className="text-center">
                    <h1>Lista de Membros</h1> 
                </ul>   
                <div className="row">
                    <div className="col-4">
                        <Link to='/alterarmembros' className="btn btn-primary" type="button"><i className="fas fa-plus"></i> Membro</Link>
                    </div>

                    <div className="col-8">
                        <div className="input-group mb-3">
                            <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" id='inputserach' />
                            <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                        </div>
                    </div>
                </div>
                <ListaMembros arrayContato={membros} clickDelete={confirmDeleteUser}/> 
                    {
                    confirmacao ? <SweetAlert
                                    warning
                                    showCancel
                                    showCloseButton
                                    confirmBtnText="Sim"
                                    confirmBtnBsStyle="danger"
                                    cancelBtnText="Não"
                                    cancelBtnBsStyle="light"
                                    title="Exclusão"
                                    onConfirm={() => deleteUser(confirmacaoId)}
                                    onCancel={() => setConfirmacao(false)}          
                                    reverseButtons={true}
                                >
                                    Deseja excluir o utilizador selecionado?
                                </SweetAlert> : null 
                        }                
           </div>
           {
                userIsLoged === false ? <Navigate to="/" replace={true} /> : null
           }                  
        </>   
           )
}

export default ConsultaMembros