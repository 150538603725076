import React from 'react'
import { ThreeCircles }  from 'react-loader-spinner'
import './loading.css'


function LoadingSpinner() {
    return (
    <div className='loading-overlay centralize'>
        <div className='position-absolute top-50 start-50 translate-middle' >
            <ThreeCircles
            visible={true}
            height="100"
            width="100"
            color="#000000"
            ariaLabel="three-circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
        </div>
    </div>
    )
}

export default LoadingSpinner